import React from 'react';
import {ErrorCodeToMessage} from 'app/components/error/ErrorCodeToMessage';
import {Error} from '@fupa/fupa-uikit';
import {object} from 'prop-types';

const ErrorAlert = ({error, email}) => {
  const {code, message} = ErrorCodeToMessage(error, email);

  return <Error errorCode={code} errorMessage={message} />;
};

ErrorAlert.propTypes = {
  error: object.isRequired,
};

export {ErrorAlert};
